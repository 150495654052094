body {
  background: #fdfdfd; //rgba(196, 196, 196, 0.25); //#f9f9fb; //#F0F6F9;
  max-width: 100vw;
  padding: 0px;
  margin: 0;
  color: #3f3f3f;
}

.page {
  padding-top: 40px;
  max-width: 1011px;
}

//bug iframe covering whole screen
iframe {
  // z-index: -100;
  //display: none;
}

.content-pane {
  border: 2px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 25px 27px;
  transition: border-color 0.6s linear;

  &.secondary {
    background: #ffffff;
    border: 1px solid rgba(63, 63, 63, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 22px 20px 18px 40px;
  }

  &:hover {
    border-color: #d82427;
  }
}

//custom table container
.table-container {
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    overflow-x: auto;
    max-width: 760px;
  }
}

.pic-small {
  width: 35px !important;
  min-width: 35px !important;
  height: 35px;
  border-radius: 50%;
  display: block;
  background: #eaedf0;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    //max-height: 100vh;
  }
}

.table {
  border: 0;

  & > :not(:last-child) > :last-child > * {
    border-bottom: thin 1px rgba(196, 196, 196, 0.25);
    border-bottom-color: rgba(196, 196, 196, 0.25);
  }

  th:first-child {
    border-radius: 25px 0px 0px 25px;
  }

  th:last-child {
    border-radius: 0px 25px 25px 0px;
  }

  td:first-child,
  th:first-child {
    //padding-left: 46px;
  }

  th {
    background: transparent !important;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #848484;
    //border: 0 !important;
    text-transform: uppercase;
    padding-top: 20px;
    padding-bottom: 14px;
  }

  td {
    border: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;

    color: #545454;
    //line-height: 14px;
    color: #565656;
    padding-top: 20px;
    padding-bottom: 14px;

    .pic-small {
      margin-top: -5px;
    }
  }

  .dropdown-toggle {
    padding: 0px 2px;
  }
}

.table-white {
  td,
  th {
    background: #ffffff !important;
  }
}

//modal
.modal-backdrop.show {
  opacity: 0.2;
}

.modal-open .modal {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.modal-small {
  .modal-content {
    max-width: 435px !important;
  }
}

.modal-medium {
  .modal-content {
    max-width: 690px !important;
  }
}

.modal-large {
  .modal-content {
    width: 900px !important;
    max-width: 100% !important;
  }
}

.modal {
  // z-index: 100000;
  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    height: 80%;

    .title {
      font-weight: 500;
      font-size: 19.5px;
      line-height: 29px;
      color: #545454;
    }

    .close {
      padding: 10px;
      cursor: pointer;
    }

    .modal-content {
      max-width: 572px;
      max-height: 100%;
      background: #ffffff;
      box-shadow: -5px 0px 50px rgba(120, 138, 156, 0.2);
      border: 0px;
      border-radius: 14.8571px;

      .modal-header {
        padding: 13px 24px;
        //height: 70px !important;
        border-bottom: solid 1px rgba(196, 196, 196, 0.22);
      }

      .modal-body {
        overflow-y: auto !important;
        padding: 10px 24px;
      }

      .modal-footer {
        //padding: 24px;
        border: 0;
      }
    }
  }
}

.f-width {
  width: 100%;
}

.v-height {
  height: 100vh;
}

.f-height {
  height: 100%;
}

.h-30 {
  font-weight: bold;
  font-size: 30px;
  line-height: 49px;
  color: #252525;
}

.h-24 {
  font-weight: bold;
  font-size: 24px;
  line-height: 39px;
  color: #252525;
}

.h-22 {
  font-weight: bold;
  font-size: 22px;
  line-height: 39px;
  color: #252525;
}

.h-20 {
  font-weight: bold;
  font-size: 20px;
  line-height: 39px;
  color: #252525;
}

.h-18 {
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #252525;
}

.h-14 {
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  color: #252525;
}

.h-12 {
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #252525;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;

  &:active,
  &:hover {
    text-decoration: none;
  }
}
.link {
  color: #164d9d;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-center {
  text-align: center !important;
}

.text-white {
  color: #ffffff;
}

.text-dark {
  color: #252525;
}

.text-darker {
  color: #000000;
}

.text-primary {
  color: #164d9d;
}

.text-success {
  color: #24a249 !important;
}

.text-danger {
  color: #d82427 !important;
}

.text-warning {
  color: #fc7b1e !important;
}

.text-brown {
  color: #b25a38;
}

.text-primary-dark {
  color: hsl(218, 60%, 21%);
}

.text-grey {
  color: #686868;
}

.text-light,
.text-label {
  color: #9b9898 !important;
}

.text-grey-light {
  color: #c0c0c2;
}

.font-bold {
  font-weight: bold;
}

.font-700 {
  font-weight: 700;
}

.font-600 {
  font-weight: 600;
}

.font-500 {
  font-weight: 500;
}

.font-normal {
  font-weight: normal;
}

.text-12 {
  font-size: 12px;
}

.text-13 {
  font-size: 14px;
}

.text-14 {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.text-22 {
  font-size: 22px;
}

.text-23 {
  font-size: 23px;
}

.text-24 {
  font-size: 24px;
}
.text-25 {
  font-size: 25px;
}
.text-26 {
  font-size: 26px;
}
.text-30 {
  font-size: 30px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-right {
  text-align: right !important;
}

.border-0 {
  border: thin 0 white;
}

.ml-0 {
  margin-left: 0;
}
.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 05px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}

.mt-33 {
  margin-top: 33px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-47 {
  margin-top: 47px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-5 {
  margin-top: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-17 {
  margin-left: 17px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-19 {
  margin-left: 19px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-85 {
  margin-left: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.bg-white {
  background: #ffffff;
}

.bg-primary {
  background: #164d9d;
}

.no-decoration {
  text-decoration: none;
}

.w-100 {
  width: 100px;
}

.w-200 {
  width: 200px;
}

.w-300 {
  width: 300px;
}

.w-400 {
  width: 400px;
}

.w-500 {
  width: 500px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.d-block {
  display: block;
}

.tag-f-w {
  display: block;
  padding: 10px 20px;
  text-align: center;
  font-size: 15px;
  height: auto;
}

.d-flex {
  display: flex;

  &.flex-end {
    justify-content: flex-end;
  }

  &.align-center {
    align-items: center;
  }

  &.justify-center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.tag {
  font-size: 15px;
  border-radius: 5px;
  //padding: 2 17px;
  width: 123px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(143, 192, 250, 0.28);
  color: #000236;

  &.primary {
    background: rgba(143, 192, 250, 0.28);
    color: #000236;
  }

  &.success {
    background: rgba(46, 248, 54, 0.28);
    color: #002b09;
  }

  &.danger {
    background: rgba(248, 46, 46, 0.28);
    color: #8f0000;
  }
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.img-f-w-obj-cover {
  width: 100%;
  object-fit: cover;
  height: 100%;
  max-height: 100vh;
}

.line,
hr {
  background: rgba(63, 63, 63, 0.7);
  height: 1px;
  //border-top: 1px solid rgba(63, 63, 63, 0.07);
}

.fixed-right {
  position: fixed;
  right: 0;

  margin-right: 0;
}

.capitalize {
  text-transform: capitalize !important;
}

/*
*  SCROLLBAR STYLING
*/

.scrollbar,
.bx--modal-content {
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: transparent//#aab0c6;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent//#aab0c6;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.22);
    border-radius: 5px;
  }
}

/*
* FADE IN STYLING
*/

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.right {
  float: right;
}

/* 
* DOT ANIMATION
*/
@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: 0.2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: 0.2;
  }
}

.dot-loader span {
  font-size: 40px;
  /**
   * Use the blink animation, which is defined above
   */
  animation-name: blink;
  /**
   * The animation should take 1.4 seconds
   */
  animation-duration: 1.4s;
  /**
   * It will repeat itself forever
   */
  animation-iteration-count: infinite;
  /**
   * This makes sure that the starting style (opacity: .2)
   * of the animation is applied before the animation starts.
   * Otherwise we would see a short flash or would have
   * to set the default styling of the dots to the same
   * as the animation. Same applies for the ending styles.
   */
  animation-fill-mode: both;
}

.dot-loader span:nth-child(2) {
  /**
   * Starts the animation of the third dot
   * with a delay of .2s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: 0.2s;
}

.dot-loader span:nth-child(3) {
  /**
   * Starts the animation of the third dot
   * with a delay of .4s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: 0.4s;
}

@media print {
  button {
    display: none !important;
  }
}

/*page main loader*/

.page-loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Circular;
  background: #e5e5e5;
  height: 100vh;
  width: 100vw;
}

.main-loader {
  color: #2186eb;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 82px;
}

@keyframes loading {
  /**
* At the start of the animation the dot
*/
  0% {
    /* color: #9CBDDE ; */
    color: linear-gradient(#9cbdde, #9cbdde, #2186eb);
    font-size: 48px;
  }

  25% {
    color: #9cbdde;
    font-size: 49px;
  }

  50% {
    color: #9cbdde;
    font-size: 50px;
  }

  75% {
    color: linear-gradient(#9cbdde, #2186eb, #2186eb);
    font-size: 49px;
  }

  95% {
    color: linear-gradient(#9cbdde, #2186eb, #2186eb);
    font-size: 48px;
  }
}

.main-loader span {
  color: #2186eb;
  font-size: 48px;
  /**
* Use the blink animation, which is defined above
*/
  animation-name: loading;
  /**
* The animation should take 1.4 seconds
*/
  animation-duration: 1.4s;
  animation-timing-function: ease-in-out;
  /**
* It will repeat itself forever
*/
  animation-iteration-count: infinite;
  /**
* This makes sure that the starting style 
* of the animation is applied before the animation starts.
* Otherwise we would see a short flash or would have
* to set the default styling of the dots to the same
* as the animation. Same applies for the ending styles.
*/
  animation-fill-mode: both;
}

.main-loader span:nth-child(2) {
  animation-delay: 0.46s;
}

.main-loader span:nth-child(3) {
  animation-delay: 0.93s;
}
