@import "~bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins"),
    url("./assets/fonts/Poppins/Poppins-Black.ttf") format("ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
