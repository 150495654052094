.book {
  margin-bottom: 35px;
  max-width: 240px;
  .book-image {
    height: 320px;
    background-image: url("../../../assets/images/book.png");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    transition: transform 0.3s linear, border-color 0.3s linear;
    border: solid 1px transparent;

    &:hover {
      border-color: rgba(216, 36, 39, 0.27);
      transform: translate(-2%, -2%);
    }
  }

  .bottom {
    padding-right: 10px;

    .book-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      transition: background-color 0.6s linear;
      &:hover {
        background-color: rgba(216, 36, 39, 0.27);
      }
    }
  }
}
