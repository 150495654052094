.program-card {
  //width: 507px;
  //height: 287px;
  cursor: pointer;
  margin-bottom: 40px;
  //left: 358px;
  //top: 563px;
  border-radius: 25px;
  border: 2px solid;
  border-color: transparent;
  transition: border-color 0.6s linear;
  background: #ffffff;
  overflow: hidden;

  &:hover {
    border-color: #d82427;
  }

  .image-pane {
    background-image: url("./icag.png");
    background-color: #c4c4c4;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 55%;
    width: 100%;
    min-height: 250px;

    &.ExecutiveTraining {
      background-image: url("./training.png");
    }
    &.CITG {
      background-image: url("./citg.png");
    }
    &.jp {
      background-image: url("./job-portal.png");
    }
  }

  .bottom {
    padding: 11px 28px 23px 28px;
    background-color: #ffffff;
    position: absolute;
    width: 100%;
    bottom: 0;
    transform: translate(0%, 0%);

    .title {
    }

    .icon_description {
      .description {
        flex: 1;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .icon {
      padding-left: 30px;
      width: 60px;
    }
  }
}
