.editor {
  .label {
    color: #05051b;
    margin-bottom: 13px;
  }

  .tox-tinymce {
    border: none;
  }

  .tox:not(.tox-tinymce-inline) .tox-editor-header {
    border: solid 3px #f0f6f9;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .tox-statusbar {
    display: none !important;
  }

  .tox .tox-edit-area__iframe {
    border-color: #f0f6f9 !important;
    background-color: #f0f6f9;
  }

  .mce-content-body {
    min-height: "136px";
    background-color: #f0f6f9;
    border: solid 1px #f0f6f9;
    border-radius: 10px important;
    padding: 16px 20px;
    line-height: 1.5;
    outline: none;
    &::before {
      color: var(--default);
      padding: 0px 20px;
      font-size: 16px;
      opacity: 0.65;
    }
    &:focus {
      border-color: var(--black);
    }
    p:last-child {
      margin-bottom: 0px;
    }
  }
}
