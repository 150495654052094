.page-layout {
  background: #f3f2f2;

  .header-container {
    background-color: #f3f2f2;
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    width: 100%;
    padding-left: 310px;
    z-index: 10;
    @media (max-width: 767.98px) {
      padding-left: 0px;
    }

    .header-bar {
      padding-left: 45px;
      padding-top: 40px;
      padding-right: 40px;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      @media (max-width: 767.98px) {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        margin-bottom: 10px;
      }

      .menu-icon {
        width: 45px;
        height: 45px;
        background-color: #d82427;
        border-radius: 7px;
        display: none;
        padding: 8px;
        margin-right: 15px;
        // Small devices (landscape phones, less than 768px)
        @media (max-width: 767.98px) {
          display: block;
        }
      }

      .back-icon {
        transform: rotate(180deg);
        padding-left: 20px;
      }

      .input-bar {
        max-width: 640px;
        padding-right: 20px;

        // Small devices (landscape phones, less than 768px)
        @media (max-width: 767.98px) {
         // display: none;
        padding-right: 5px;
        }
      }

      .notification-icon{
        // Small devices (landscape phones, less than 768px)
          @media (max-width: 767.98px) {
             display: none;
          }
      }
      .user-info {
        //width: 100px;
        margin-left: 50px;
        display: flex;
        align-items: center;

        .user-initials {
          background: #000000;
          font-weight: 600;
          font-size: 25px;
          line-height: 37px;
          color: #ffffff;
          width: 50px;
          height: 50px;
          border-radius: 25px;
          margin-right: 10px;
          padding: 8px;
          text-align: center;
        }

                @media (max-width: 767.98px) {
                    margin-left: 10px;
                }
      }
    }
  }

  .page-content {
    padding-left: 310px;
    //max-width: 1011px;
    padding-bottom: 50px;
    height: 100vh;
    overflow-y: auto;
    //box-shadow: -2px 4px 40px rgba(0, 0, 0, 0.2);

    .page-inner {
      max-width: 1130px;
      padding-top: 120px;
      width: 100%;
      padding-left: 45px;
      padding-right: 40px;
      @media (max-width: 767.98px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    @media screen and (max-width: 1020px) {
      // margin-left: 260px; //280
    }

    @media screen and (max-width: 900px) {
      // margin-left: 240px; //260
    }

    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
      padding-left: 0px;
    }
  }
}
