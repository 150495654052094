.custom-button {
  border-radius: 10px;
  height: 64px;
  display: inline-block;
  text-align: center;
  padding: 0;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
  font-size: 23px;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: #8c0405;

  :hover {
    opacity: 0.6;
  }

  .button-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .button-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  //type small
  &.small {
    height: 57px;
    font-weight: 500;
    font-size: 23px;
    padding-left: 25px;
    padding-right: 25px;
  }

  &:focus {
    outline: none;
  }

  &.primary {
    background-color: #164d9d;
  }

  &.secondary {
    background-color: #05051b;
  }

  &.info {
    background-color: #eaedf0;
    color: #0a243f;
  }

  &.success {
    background-color: #158213;
  }

  &.danger {
    background-color: #d82427;
  }

  &.outline {
    background-color: transparent;
    border: 1px solid;
    box-sizing: border-box;

    &.no-border {
      border: 0 !important;
    }

    &.no-hover {
      &:hover {
        background-color: initial !important;
        color: initial !important;
      }
    }

    &.primary {
      border-color: #164d9d;
      color: #164d9d;

      &:hover {
        background-color: #164d9d;
        color: #fff;
      }
    }

    &.secondary {
      border-color: #848484;
      color: #848484;

      &:hover {
        background-color: #05051b;
        color: #fff;
      }
    }

    &.success {
      border-color: #24a249;
      color: #24a249;
    }

    &.danger {
      border-color: #da1e28;
      color: #da1e28;

      &:hover {
        background-color: #da1e28;
        color: #fff;
      }
    }
  }

  /* pill type*/
  &.pill {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #0a243f;
    padding: 4px 11px 6px 12px;
    border-radius: 8px;
    height: 35px !important;
    border: 0;
    background: transparent;

    &.success {
      //is active
      &.true,
      &.active,
      &:hover {
        font-weight: bold;
        font-size: 15px;
        color: #077f7f;
        background: rgba(7, 126, 126, 0.1);
      }
    }

    &.info {
      color: #0a243f;
      background: #eaedf0;
    }

    &.secondary {
      color: #ffffff;
      background: #05051b;
    }
  }

  &.f-width {
    width: 100%;
  }
}
