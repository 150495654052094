.reset-password-page {
  //display: flex;
  //align-items: center;
  padding: 100px 20px;
  background: #fdfdfd;
  min-height: 100vh;

  .logo-div {
    display: flex;
    justify-content: center;
    margin-top: -40px;
  }

  .text-29 {
    font-size: 29px;
  }

  .left-header {
    font-weight: 600;
    font-size: 45px;
    line-height: 67px;
    color: #ffffff;
    text-align: center;
    margin-top: -50px;
  }

  .right-pane,
  .left-pane {
    display: flex;
    align-items: center;
    justify-content: center;
    //min-height: 600px;
    padding: 0;
    margin: 0;
    flex: 1;
  }
  .left-pane {
    background: #d82427;
    //height: 100vh;
  }
  .right-pane-inner {
    width: 100%;
    padding: 84px 87px 51px 81px;
    @media (max-width: 767.98px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .inner {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0;
    background: #ffffff;
    border: 1px solid rgba(104, 104, 104, 0.05);
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 29px;
    overflow: hidden;
  }
}
