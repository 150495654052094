.input-field {
  width: 100%;
  label {
    font-size: 14px;
    line-height: 16px;
    color: #565656;
    margin-bottom: 13px;
  }

  .input-container {
    background: #ffffff;
    border-radius: 8px;
    height: 64px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    transition: border-color 0.6s linear;
    border: 1px solid rgba(63, 63, 63, 0.2);
    border-radius: 10px;
    padding: 0 26px;

    &:hover {
      border-color: rgba(216, 36, 39, 0.27);
    }

    &.transparent {
      background: transparent;
    }
  }

  &.rounded {
    .input-container {
      border-radius: 46px !important;
    }
  }

  &.small {
    .input-container {
      height: 53px;
      box-sizing: border-box;
      //padding: 9px 10px;
      border-radius: 6.5px;
      &:hover {
        border-color: rgba(216, 36, 39, 0.27);
      }
    }

    input {
      &::placeholder {
        font-size: 15px;
      }
    }
  }

  input {
    width: 100%;
    flex: 1;
    border: 0;
    outline: none;
    background: transparent !important;
    padding: 5px 0px;
    font-size: 16px;
    color: #05051b;

    &:focus,
    &:active {
      background: transparent !important;
      border: 0;
      outline: none;
    }

    &::placeholder {
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      color: #686868;
    }
  }

  .left-icon {
    margin-right: 15px;
  }
}
