.sidebar {
  background: #ffffff;
  padding: 0px 10px 80px 0px;
  position: fixed;
  //min-height: 100vh;
  height: 100vh;
  width: 310px !important;
  z-index: 20;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.09);
  /* @media screen and (max-width: 1020px) {
    padding-left: 30px;
    width: 220px !important;
  }

  @media screen and (max-width: 900px) {
    padding-left: 20px;
    width: 200px !important;
  } */

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    display: none;
  }

  &.show {
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
      display: block;
    }
  }

  .logo {
    margin-left: 30px;
  }

  .login-name {
    //width: 190px !important;
    text-align: right;
    height: 40px;
    background: #fff;
    border-radius: 25px;
    padding: 7px 14px;
    z-index: -1;
    position: absolute;
  }

  .side-top {
    //width: 276;
    margin-bottom: 90px;

    .close-menu {
      display: none;
      width: 45px;
      height: 45px;
      background-color: #d82427;
      border-radius: 5px;
      transform: rotate(270deg);
      padding: 15px;
      // Small devices (landscape phones, less than 768px)
      @media (max-width: 767.98px) {
        display: flex;
      }
    }
  }

  .side-bottom {
    //display: flex;
    width: 310px;
    padding: 20px 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #ffffff;
    //border-top: solid 1px rgba(196, 196, 196, 0.22);
    .download-pane {
      width: 100%;
      padding: 24px 30px 30px 18px;
      background: rgba(216, 36, 39, 0.08);
      border-radius: 20px;
    }
  }

  .side-middle {
    padding-bottom: 220px;
  }

  .side-nav-tab {
    display: flex;
    align-items: center;
    height: 60px;
    margin-bottom: 8px;
    font-weight: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    color: #545454;
    padding: 4px 10px 6px 40px;
    cursor: pointer;
    //width: 197px;
    background-color: transparent;
    border-radius: 0px 25px 25px 0px;
    transition: background-color 0.6s linear;

    //is active
    &.true,
    &:hover {
      color: #fdfdfd;
      background-color: #d82427;
    }

    .side-nav-icon {
      width: 21px;
      margin-right: 20px;
    }
  }

  .dropdown-icon {
    width: 20;
    margin-left: auto;
    transform: rotate(280deg);

    &.true {
      transform: rotate(0deg);
    }
  }

  .sub-nav {
    cursor: pointer;
    margin-bottom: 3px;
    //width: 120px;
    padding: 10px 3px 10px 45px;
    color: #545454;
    font-weight: 500;
    font-size: 15px !important;

    &.true,
    &:hover {
      color: #8f0000;
      background: rgba(248, 46, 46, 0.28);
      border-radius: 5px;
    }
  }
}
